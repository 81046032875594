<template>
    <section class="content redesign">
        <div class="row">
            <div class="col-lg-6 mt-3">
                <div class="card">
                    <div class="card-header h6">
                        Informasi Pengiriman
                    </div>
                        <!-- <div class="p-5"></div> -->
                    <div class="card-body">
                        <div class="col-xs-12 form-group">
                            <label class="control-label">ASAL PENGIRIMAN</label>
                            <!-- <v-select :options="senderOptions" v-model="formData.sender_addr_id" :reduce="opt => opt.id" /> -->
                            <v-select required label="name" name="sender_addr_id" :filterable="false" :options="addrOptions1" :getOptionLabel="addrOptions1 => formData.sender_addr_id" v-model="formData.sender_addr_id" :reduce="(opt) => opt.id" @search="onSearch1" >
                            <template slot="no-options"> Ketik asal pengiriman... </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.lokasi }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.lokasi }}
                                </div>
                            </template>
                            </v-select>
                        </div>
                        <div class="col-xs-12 form-group">
                            <label class="control-label">TUJUAN PENGIRIMAN</label>
                            <v-select required label="name" name="addr_id" :filterable="false" :options="addrOptions2" :getOptionLabel="addrOptions2 => formData.addr_id" v-model="formData.addr_id" :reduce="(opt) => opt.id" @search="onSearch2">
                            <template slot="no-options"> Ketik tujuan pengiriman... </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.lokasi }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.lokasi }}
                                </div>
                            </template>
                            </v-select>
                        </div>
                        <div class="col-xs-12 form-group">
                            <label class="control-label">BERAT (Kg)</label>
                            <input @change="resetOngkir()" class="form-control" v-model="formData.weight" type="number" value="1" min="0.5" max="99" step="0.1" required="required" placeholder="Bisa desimal" name="weight" 
                            onkeydown="return (event.ctrlKey || event.altKey 
                            || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                            || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                            || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                        </div>
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label class="control-label">Panjang (Cm)</label>
                                <input  @change="resetOngkir()" class="form-control" v-model="formData.l" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="l" 
                                onkeydown="return (event.ctrlKey || event.altKey 
                                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                                || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                                || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="control-label">Lebar (Cm)</label>
                                <input  @change="resetOngkir()" class="form-control" v-model="formData.w" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="w" 
                                onkeydown="return (event.ctrlKey || event.altKey 
                                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                                || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                                || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="control-label">Tinggi (Cm)</label>
                                <input  @change="resetOngkir()" class="form-control" v-model="formData.h" type="number" value="1" min="0.5" step="0.1" required="required" placeholder="Bisa desimal" name="h" 
                                onkeydown="return (event.ctrlKey || event.altKey 
                                || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                                || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                                || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                            </div>
                        </div>
                        <div class="form-group">
							<div class="row">
								<div class="col">
									<label class="font-weight-normal">Apa tipe pengirimanmu?</label>
								</div>
							</div>
							<div class="form-group">
							<div class="row">
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : formData.codStatus }" v-bind:style="{ backgroundColor: formData.codStatus ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<!-- <div class="icheck-material-orange w-100" @click="!!codDisabled && verifKtp()"> -->
										<div class="icheck-material-orange w-100">
											<!-- <input type="radio" id="cod" name="cod-status" v-model="formData.codStatus" v-bind:value="true" @change="onToggleChange($event)" :disabled="codDisabled" required /> -->
											<input type="radio" id="cod" name="cod-status" v-model="formData.codStatus" v-bind:value="true" required />
											<label for="cod" class="form-check-label w-100">COD</label>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="form-control h-100" v-bind:class="{ 'border-primary' : !formData.codStatus }" v-bind:style="{ backgroundColor: !formData.codStatus ? '#ffe1c9' : '#fff' }" style="border-width: 2px;">
										<div class="icheck-material-orange w-100">
											<input type="radio" id="reg" name="cod-status" v-model="formData.codStatus" v-bind:value="false" required />
											<label for="reg" class="form-check-label w-100">Non - COD</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
                        <div class="col-xs-12 form-group">
                            <label class="control-label">Nilai Barang</label>
                            <input @change="hitungBiaya()" class="form-control" v-model="formData.item_value" type="number" value="1" min="0.5" max="99" step="0.1" required="required" placeholder="Bisa desimal" name="weight" 
                            onkeydown="return (event.ctrlKey || event.altKey 
                            || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                            || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                            || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                        </div>
                        <div class="col-xs-12 form-group" v-if="formData.codStatus">
                            <label class="control-label">Nilai COD</label>
                            <input @change="hitungBiaya()" class="form-control" v-model="formData.codValue" type="number" value="1" min="0.5" max="99" step="0.1" required="required" placeholder="Bisa desimal" name="weight" 
                            onkeydown="return (event.ctrlKey || event.altKey 
                            || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) 
                            || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                            || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"/>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" :disabled="checking" v-on:click="search" class="btn btn-primary btn-lg btn-block"><i class="fas fa-search"></i>&nbsp;Cek Ongkos Kirim <span v-if="checking" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
				<div class="card border rounded mb-3">
                    <div class="card-header h6">
                        Kurir Pengiriman
                    </div>
					<div class="card-body">
                        <div class="form-group mb-2">
							<!-- <div class="row">
								<div class="col d-flex justify-content-between">
									<label>Kurir Pengiriman</label>
								</div>
							</div> -->
							<div v-if="showServiceList" class="d-flex flex-column" style="gap: 0.5rem;">
								<div class="form-control h-100 py-3 cursor-pointer" v-on:click="selectService(sid, list.drop_off)" v-for="(list, sid) in resultOngkir" v-bind:key=sid>
									<div class="row align-items-center">
										<div class="col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="max-width: 30%;max-height: 33.275px">
											<img :src="getKurirLogo(list.kurir)" style="max-width: 100%;max-height: 60px;" />
										</div>
										<div class="col">
											<div class="font-weight-bold">{{ list.kurir + ' ' + list.serviceName }}</div>
											<div class="text-xs">{{ list.etd_from == 0 || list.etd_thru == 0 ? '&#8212;' : list.etd_from + '-' + list.etd_thru + ' hari' }}</div>
										</div>
										<div class="col-auto text-right">
											<del class="text-xs text-danger">{{ list.disc != 0 ? 'Rp ' + formatPrice(list.maxPrice) : '&nbsp;' }}</del>
											<div class="font-weight-bold">Rp {{ list.ppn_ongkir_inc == 0 ? formatPrice((list.price - list.disc) * (100 + list.ppn_ongkir) / 100) : formatPrice(list.price - list.disc) }}</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else-if="service.id" class="form-control h-100 py-3 border-primary cursor-pointer" style="background-color: #ffe1c9;border-width: 2px;" v-on:click="showServiceOption">
								<div class="row">
									<div class="col-auto d-flex justify-content-center align-items-center px-0 px-sm-2" style="max-width: 30%;max-height: 33.275px">
										<img :src="getKurirLogo(service.kurir)" style="max-width: 100%;max-height: 60px;" />
									</div>
									<div class="col">
										<div class="font-weight-bold">{{ service.kurir + ' ' + service.serviceName }}</div>
										<div class="text-xs">{{ service.etd_from == 0 || service.etd_thru == 0 ? '&#8212;' : service.etd_from + '-' + service.etd_thru + ' hari' }}</div>
									</div>
									<div class="col-auto text-right">
										<del class="text-xs text-danger">{{ service.disc != 0 ? 'Rp ' + formatPrice(service.maxPrice) : '&nbsp;' }}</del>
										<div class="font-weight-bold">Rp {{ service.ppn_ongkir_inc == 0 ? formatPrice((service.price - service.disc) * (100 + service.ppn_ongkir) / 100) : formatPrice(service.price - service.disc) }}</div>
									</div>
								</div>
							</div>
							<div v-else class="form-control d-flex justify-content-between align-items-center h-100 py-3 cursor-pointer">
								<div class="text-muted">
									<div class="font-weight-bold">Pilih kurir pengirimanmu</div>
									<div class="mt-2">Pilih kurir favoritmu dan nikmati diskon dari bosCOD!</div>
								</div>
								<!-- <i class="fi fi-rr-angle-right" style="opacity: 25%;"></i> -->
							</div>
							<div class="row mt-2" v-if="formData.addr_id && formData.confirm && ongkirInProgres.length < listCourier.length">
								<div class="col">
									<div class="progress rounded-pill">
										<div class="progress-bar progress-bar-striped progress-bar-animated" :class="['bg-primary']" role="progressbar" v-bind:aria-valuenow="(ongkirInProgres.length / listCourier.length) * 100" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': (ongkirInProgres.length / listCourier.length) * 100 + '%' }">{{ (ongkirInProgres.length / listCourier.length) * 100 }}%</div>
									</div>
								</div>
								<div class="col-auto pl-0">
									<i class="fi fi-rr-cross cursor-pointer" v-on:click="stopLoadOngkir"></i>
								</div>
							</div>
						</div>
                    </div>
                </div>
				<div class="card border rounded mb-3">
                    <div class="card-header h6">
                        Rincian Pembayaran
                    </div>
					<div class="card-body">
						<!-- <label class="mb-2">Rincian Pembayaran</label> -->
                        <div class="form-group mb-0" :class="{ 'mt-1' : formData.codStatus }" v-if="formItem.nilai_ongkir && (!formData.codStatus && !showServiceList || (formData.codStatus && formItem.feeCod && !showServiceList))">
							<div v-if="formData.codStatus && formData.codValue>0" class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ formData.weight  < service.weight ? '(Volumetrik)' : '' }}</span>
									<span>{{ formData.weight  < service.weight ? service.weight :formData.weight }}  Kg</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai Paket</span>
									<span>Rp {{ formatPrice(formData.item_value) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai COD</span>
									<span>Rp {{ formatPrice(formData.codValue) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : formData.codStatus }" @click="toggleDetail">Ongkir <span v-if="formData.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="formData.codStatus"></i></span>
									<span>Rp {{ formatPrice(formData.codStatus ? formItem.nilai_ongkir + formItem.feeCod : formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(formItem.feeCod) }}</span>
								</div>
								<!-- <div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div> -->
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ formData.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((formData.codStatus && formItem.totalReceived > 0) || (!formData.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(formData.codStatus ? (formData.codValue ? formItem.totalReceived : formData.item_value) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div v-else-if="formData.codValue" class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ formData.weight  < service.weight ? '(Volumetrik)' : '' }}</span>
									<span>{{ formData.weight  < service.weight ? service.weight :formData.weight }}  Kg</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai Paket</span> 
									<span>Rp {{ formatPrice(formData.item_value) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : formData.codStatus }" @click="toggleDetail">Ongkir <span v-if="formData.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="formData.codStatus"></i></span>
									<span>Rp {{ formatPrice(formData.codStatus ? formItem.nilai_ongkir + formItem.feeCod : formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(formItem.feeCod) }}</span>
								</div>
								<!-- <div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div> -->
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ formData.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((formData.codStatus && formItem.totalReceived > 0) || (!formData.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(formData.codStatus ? (formData.codValue ? formItem.totalReceived : formData.item_value) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div v-else class="d-flex flex-column mt-1" style="gap: 6px">
								<div class="d-flex justify-content-between">
									<span>Berat Paket {{ formData.weight  < service.value ? '(Volumetrik)' : '' }}</span>
									<span>{{ formData.weight  < service.weight ? service.weight :formData.weight }}  Kg</span>
								</div>
								
								<div class="d-flex justify-content-between" v-if="formData.codStatus">
									<span>Nilai COD</span>
									<span>Rp {{ formatPrice(formData.codStatus ? (formData.codValue > 0 ? formData.codValue : ((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * (formItem.fee_cod / 100)))))) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span>Nilai Paket</span>
									<span>Rp {{ formatPrice(formData.item_value) }}</span>
								</div>
								<div class="d-flex justify-content-between">
									<span :class="{ 'cursor-pointer' : formData.codStatus }" @click="toggleDetail">Ongkir <span v-if="formData.codStatus">dan COD Fee</span> (Termasuk PPN) <i class="fi" :class="( showDetail ? 'fi-rr-angle-small-up' : 'fi-rr-angle-small-down' )" v-if="formData.codStatus"></i></span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir + Math.round(((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) + (((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) * (formItem.ppnCod / 100)))) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>Ongkir + PPN</span>
									<span>Rp {{ formatPrice(formItem.nilai_ongkir) }}</span>
								</div>
								<div class="d-flex justify-content-between" style="opacity: .5" v-if="formData.codStatus && showDetail">
									<span>COD Fee + PPN</span>
									<span>Rp {{ formatPrice(((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) + (((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * formItem.fee_cod / 100))) * (formItem.fee_cod / 100)) * (formItem.ppnCod / 100)))}}</span>
								</div>
								<!-- <div class="d-flex justify-content-between">
									<span>Biaya Asuransi</span>
									<span>Rp {{ formatPrice(formItem.insurance_fee) }}</span>
								</div> -->
								<hr class="m-0 border-secondary" style="border-style: dashed;border-width: thin;" />
								<div class="d-flex justify-content-between">
									<span>{{ formData.codStatus ? 'Nilai yang kamu terima' : 'Total' }}</span>
									<span class="font-weight-bold" :style="{ 'color' : ((formData.codStatus && formItem.totalReceived > 0) || (!formData.codStatus && formItem.nilai_ongkir + formItem.insurance_fee > 0) || (availableCost == true) ? '#00ab4f' : '#ab0007') }">Rp {{ formatPrice(formData.codStatus ? (formData.codValue ? formItem.totalReceived : parseInt(formData.item_value)) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
							<div class="mt-2 pt-1">
								<div class="d-flex justify-content-between font-weight-bold">
									<span>{{ formData.codStatus ? 'Ditagihkan ke customer' : 'Total Pembayaran' }}</span>
									<span>Rp {{ formatPrice(formData.codStatus ? (formData.codValue > 0 ? formData.codValue : ((parseInt(formData.item_value) + formItem.nilai_ongkir + formItem.insurance_fee) / (1-((formItem.fee_cod / 100) + ((formItem.ppnCod / 100) * (formItem.fee_cod / 100)))))) : formItem.nilai_ongkir + formItem.insurance_fee) }}</span>
								</div>
							</div>
						</div>
						<div class="row mb-5" v-else >
							<div class="col-12 col-sm-8 col-md-5 col-lg-auto m-auto">
								<img src="../assets/img/search-engines-amico.svg" class="d-flex m-auto" />
								<div class="text-center">
									<span class="font-20-bold">Rincian Pembayaran Kosong</span><br/>
									<span class="font-14-medium text-neutral-dark-03">Lengkapi terlebih dahulu data kalkulator pengiriman dan pilih kurir yang tersedia</span>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
			<div class="col-lg-6 mt-3">
				
            </div>
        </div>
        

        

        <!-- <div
            v-for="row in resultOngkir"
            v-bind:key="row.service"
            v-bind:value="row.service"
            class="col-md-12">
        
        </div> -->
        
    </section>
</template>
<script>
import { createTable, authFetch, formatCurrency } from '@/libs/hxcore';
import $ from 'jquery';
// import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";
import "icheck-material";

export default {
    name: 'Calculator',
    components: {
        vSelect,
        // datepicker,
    },
    data() {
        return {
            errors: [],
            addrOptions1: [],
            addrOptions2: [],
            senderOptions: [],
            resultOngkir: [],
            roles: '',
            checking: false,
            showDetail:false,
            formData: {
                addr_id: '',
                sender_addr_id: '',
                weight: 1,
                w: 10,
                l: 10,
                h: 10,
                codStatus: true,
                insurance_type:true,
                codValue: 0,
                item_value:0
            },
            onChange:true,
            // tgl: '',
            // kelas: '',
            signals: null,
            totalCourier: [],
            listCourier: [],
            ongkirInProgres: [],
			service: {
				id: "",
				kurir: "",
				service: "",
				serviceName: "",
				etd_from: 0,
				etd_thru: 0,
				price: 0,
				maxPrice: 0,
				disc: 0,
				ppn: 0,
				ppn_ongkir: 0,
				ppn_ongkir_inc: 0,
				ins: 0,
				insmin: 0,
				insfee: 0,
				feecod: 0,
                weight:0
			},
            formItem: {
				nilai_ongkir: 0,
				feeCod: 0,
				ppnCod: 0,
				insurance_fee: 0,
				totalReceived: 0,
			},
            availableCost:true,
            showServiceList:false
        }
    },
    created: function() {
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)
        //this.loadAddress();
        this.loadSenderAddress();
    },
    methods: {
        background(def){
			console.log('warma',this.formData.codStatus)
            return this.formData.codStatus==def ? '#ffe1c9' : 'fff';
            // return 'fff';
        },
        formatPrice(data){
            return formatCurrency(data);
        },
        getKurirLogo(kurir) {

            const fileName = kurir.toLowerCase();

            return require(`../assets/img/${fileName}.png`) // the module request
        },
        search: function() {
            let self = this;
            self.formData.addr_id = self.formData.addr_id;
            self.formData.sender_addr_id = self.formData.sender_addr_id;
            self.formData.weight = self.formData.weight;
            if (!self.formData.sender_addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Asal gudang belum dipilih!", ``, "error");
            }
            if (!self.formData.addr_id) {
                self.resultOngkir = [];
                return Swal.fire("Tujuan pengiriman belum dipilih!", ``, "error");
            }
            if (self.formData.weight > 99) {
                self.resultOngkir = [];
                return Swal.fire("Berat maksimum 99 Kg!", ``, "error");
            }
			if(self.formData.weight == 0){
				return Swal.fire("Berat harus di isi", ``, "error");
			}
            //this.loadOngkir(self.form);
            this.stopLoadOngkir();
            this.mapOngkir();
        },
        stopLoadOngkir: function() {
			if(this.signals!=null){
				this.signals.abort();
				this.ongkirInProgres = [];
			}
		},
        loadAddress() {
            let route;
                route = "/order/cekongkir/address";
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        // this.sender = json.data;
						
                        this.addrOptions1.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                        this.addrOptions2.push({
                            id: json.data.addr_id,
                            lokasi: json.data.kota + " / " + json.data.kecamatan,
                        });
                    }
                });
            });
        },
        loadSenderAddress() {
            authFetch('/order/cekongkir/asalgudang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.senderOptions = js.data;
                $('.loading-overlay').removeClass('show');
            });
        },
        onSearch1(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions1 = json.items));
                    loading(false);
                });
            }
        },
        onSearch2(search, loading) {
            if (search.length > 3) {
                loading(true);
                //this.search(loading, search, this);
                var vm = this;
                authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
                    res.json().then((json) => (vm.addrOptions2 = json.items));
                    loading(false);
                });
            }
        },
        loadOngkir(params) {
            const self = this;
            let data;
            let route;
            data = Object.keys(params)
            .map(
                (key) =>
                encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
            )
            .join("&");
            route = "/order/cekongkir/cek?" + data;
            // var weight = self.formItem.weight;
            // if (!weight) weight = 1;
            // if (s_id) {
            //     route = `/order/nilai_ongkir/${courier_id}/${s_id}/${r_id}/${weight}`;
            // } else {
            //     route = "/order/nilai_ongkir/854/887";
            // }
            // if (self.formData.shipper_id) {
            //     route = route + '?shipper_id=' + self.formData.shipper_id;
            // }
            this.checking = true;
            authFetch(route).then((res) => {
                res.json().then((json) => {
                    console.log("data ", json);
                    self.resultOngkir = json;
                    this.checking = false;
                });
            });
        },
        async loadCourier() {
			// this.resetService();
			let route = "/order/kurir";
			try {
				await authFetch(route).then(res => {
					res.json().then(json => {
						if (json.success == true) {
							this.listCourier = json.data;
							this.mapOngkir();
						} else {
							Swal.fire({
								title: "Oops...",
								icon: "error",
								text: json.message,
								showCloseButton: true
							});
						}

					});
				});
			} catch (error) {
				
			}
		},
        mapOngkir() {
            const self = this;
            this.ongkirInProgres =[];
			let data;
			this.ongkirInProgres =[];
			this.resultOngkir = [];
			if (this.signals != null) {
				this.signals.abort();
			}
			const controller = new AbortController();
			this.signals = controller;
			data = this.listCourier.length > 0 ? this.listCourier.map((val,key) => {
				var data = {};
				data.addr_id = self.formData.addr_id;
				data.sender_addr_id = self.formData.sender_addr_id;
				data.weight = self.formData.weight;
				data.courier = val.id;
				data.order_type = this.formData.order_type;
				data.w = self.formData.w;
				data.h = self.formData.h;
				data.l = self.formData.l;
				this.newLoadOngkir(data);
			}) : this.loadCourier();
		},
		newLoadOngkir(params) {
			const self = this;
			params.codStatus=0;
			const courierRecommendation = 1; //idx
			//param.courier=this.listCourier;
			let data;
			let route;
			data = Object.keys(params)
				.map(
					key =>
						encodeURIComponent(key) +
						"=" +
						(params[key] === false || params[key] === undefined
							? 0
							: encodeURIComponent(params[key]))
				)
				.join("&");
			route = "/order/ongkir?" + data;
			this.checking = true;
			this.loadingOngkir = true;

			authFetch(route,{signal : this.signals.signal}).then(response => {
				response.json().then(json => {
					if(params.courier ==  courierRecommendation){
						console.log(params.courier);
						const insertAt = 0;
						self.resultOngkir = [...json].concat(self.resultOngkir);
						// self.resultOngkir.push(...json);
					}else{
						self.resultOngkir.push(...json);
					}
					this.checking = false;
					this.loadingOngkir = false;
					if (Object.keys(this.resultOngkir).length == 1 && Object.keys(this.listCourier).length == 1) {
						this.selectService(0);// if only one option
					} else {
						this.showServiceList = true;
					}
					this.ongkirInProgres.push(...params.courier);
				})
				// .finish(
				// 	this.ongkirInProgres.push(...params.courier)
				// );
			});
		},
		showServiceOption: function()
		{
			if (this.resultOngkir.length === 1) return;
				this.showServiceList = true;
				this.resetService();
		},

		resetOngkir: function()
		{
			this.resultOngkir = []
            this.showServiceList = false;
            this.resetService();
		},
		resetService: function() {
			this.service = {
				id: "",
				kurir: "",
				service: "",
				serviceName: "",
				etd_from: 0,
				etd_thru: 0,
				price: 0,
				maxPrice: 0,
				disc: 0,
				ppn: 0,
				ppn_ongkir: 0,
				ppn_ongkir_inc: 0,
				ins: 0,
				insmin: 0,
				insfee: 0,
				feecod: 0,
                weight:0
			};
		},
        selectService: function(sid, item)
		{
			// var now = moment();
			// var tomorrow = moment().add(1,'days');
			this.service = this.resultOngkir[sid];
			// this.form.courier_id = this.service.id;
			this.showServiceList = false;
			// this.formData.weight = this.service.weight;

			// if (item == "1") {
			// 	this.droppOffDisabled = false;
			// } else {
			// 	this.droppOffDisabled = true;
			// 	this.form.order_type = "0";
			// }

			// if (this.og_status == 1 || this.pickTimeExcept.includes(this.service.kurir)) {
			// 	if (this.service.kurir == 'JNTCargo'){
			// 		Swal.fire({
			// 		title: "Info Waktu Penjemputan",
			// 		icon: "info",
			// 		html:
			// 			'<h5>Kurir <b>JNT Cargo</b><br></h5> ' +
			// 			'Order sebelum jam 15.00 akan di pickup pada hari yang sama dan <br> order setelah jam 15.00 akan dipickup pada hari berikutnya',
			// 		showCloseButton: true
			// 	});
			// 	} else {
			// 		Swal.fire({
			// 		title: "Info Waktu Penjemputan",
			// 		icon: "info",
			// 		html:
			// 			'<h5>Kurir <b>'+this.service.kurir+' Express</b><br></h5> ' +
			// 			'Order sebelum jam 15.00 akan di pickup pada hari yang sama dan <br> order setelah jam 15.00 akan dipickup pada hari berikutnya',
			// 		showCloseButton: true
			// 	});
			// 	}
			// } else if (this.og_status == 1 || this.ServicePickTimeExcept.includes(this.service.service)) {
			// 	Swal.fire({
			// 		title: "Info Waktu Penjemputan",
			// 		icon: "info",
			// 		html:
			// 			'<h5>Service <b>'+this.service.service+" </b> kurir <b> "+this.service.kurir+' Express</b><br></h5> ' +
			// 			'Order sebelum jam 12.00 akan di pickup pada hari yang sama dan <br> order setelah jam 12.00 akan dipickup pada hari berikutnya',
			// 		showCloseButton: true
			// 	});
			// }

			// if (moment() < moment("08:00", 'HH:mm')) {
			// 	this.form.pickup_time = now.format("YYYY-MM-DD 08:00:00");
			// 	this.form.pickupend_on = now.format("YYYY-MM-DD 12:00:00");
			// } else if (moment() < moment("12:00", 'HH:mm')) {
			// 	this.form.pickup_time = now.format("YYYY-MM-DD 12:00:00");
			// 	this.form.pickupend_on = now.format("YYYY-MM-DD 16:00:00");
			// } else if (moment() < moment("16:00", 'HH:mm')) {
			// 	this.form.pickup_time = now.format("YYYY-MM-DD 16:00:00");
			// 	this.form.pickupend_on = now.format("YYYY-MM-DD 20:00:00");
			// } else {
			// 	this.form.pickup_time = tomorrow.format("YYYY-MM-DD 08:00:00");
			// 	this.form.pickupend_on = tomorrow.format("YYYY-MM-DD 12:00:00");
			// }

			this.hitungBiaya();
			// this.submitType = 'draft';
			// this.form.submit = 'draft'
		},
        
		getBiayaAsuransi(item, service)
		{
			// if (!this.formData.insurance_type) return 0;
			var itemVal = item.item_value;
			var insFee = 0;
			if (itemVal > service.insmin) {
				if (service.insfee) {
					insFee = service.insfee;
				} else if (service.ins) {
					insFee = (service.ins * itemVal) / 100;
				}
			}
			// return insFee;
            return 0
		},
		getBiayaOngkir(item, service)
		{
			var nilai_ongkir = service.price - service.disc;
			if (service.ppn_ongkir_inc == 0) {
				// var xnilai_ongkir = nilai_ongkir * 100 / (100 + service.ppn_ongkir);
				var xnilai_ongkir = Math.round((nilai_ongkir * (100 + service.ppn_ongkir)) / 100);
				nilai_ongkir = xnilai_ongkir;
				// nilai_ongkir = nilai_ongkir * 1.01; // +ppn 1%
			}
			return nilai_ongkir;
		},
		getBiayaCOD(item, service)
		{
			if (item.codStatus) {
				var codFee = ((item.codValue > 0 ? item.codValue : item.item_value) * service.feecod) / 100;
				var totalFeeCod = codFee + (codFee * service.ppn) / 100;
				return totalFeeCod;
			} 
			return 0;
		},

        hitungBiaya: function()
		{
			// if (!this.sender.id || !this.receiver.confirm || !this.item.confirm || !this.service.id) {
			// 	return;
			// }

			if (this.formData.codValue > 0) {
				var cod_value = this.formData.codValue;
			} else if (this.formData.codStatus) {
				var cod_value = this.formData.item_value;
			} else {
				this.formData.codValue = 0;
			}

			var nilai_ongkir = this.getBiayaOngkir(this.formData, this.service);
			var feeCod = this.getBiayaCOD(this.formData, this.service);
			var insFee = this.getBiayaAsuransi(this.formData, this.service);
			this.formItem.nilai_ongkir = nilai_ongkir;
			this.formItem.feeCod = feeCod;
			this.formItem.ppnCod = this.service.ppn;
			this.formItem.fee_cod = this.service.feecod;
			this.formItem.insurance_fee = insFee;
			this.formItem.totalReceived = cod_value - nilai_ongkir - feeCod - insFee;
			if (this.formData.codStatus) {
				if (this.formData.codValue && this.formItem.totalReceived < 1) {
					this.availableCost = false;
				} else {
					this.availableCost = true;
				}
			} else {
				this.availableCost = true;
			}
		},
        
		toggleDetail() {
			this.showDetail = !this.showDetail;
		},
		onToggleChange(event) {
			// added event as second arg
			this.formData.codStatus = event.target.value;
			this.hitungBiaya();
		},
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;
    },
	computed: {
		codStatus: function() {
			return this.formData.codStatus;
		},
		senderAddress: function() {
			return this.formData.sender_addr_id;
		},
		receiverAddress: function() {
			return this.formData.addr_id;
		},
		nilaiCod: function() {
			return this.formData.nilaiCod;
		}
	},
    watch: {
		ongkirInProgres: function(val) {
			//do something when the data changes.
			Object.keys(this.ongkirInProgres).length==Object.keys(this.listCourier).length==1
			if (Object.keys(this.ongkirInProgres).length==Object.keys(this.listCourier).length==1) {
				this.signals=null;
			}
		},
		codStatus: function(val) {
			this.hitungBiaya();
		},
		senderAddress: function(){
			this.resetOngkir();
		},
		receiverAddress: function(){
			this.resetOngkir();
		},
		nilaiCod: function(val) {
			this.hitungBiaya();
		},

	},
}
</script>

<style scoped>
.description-text{
	font-size: 15px;
}
.btn-circle {
	width: 15px;
	height: 15px;
	padding: 0px 0px;
	border-radius: 10px;
	text-align: center;
	font-size: 10px;
	line-height: 1.42857;
}
@media (max-width: 575.98px) {
	.position-sticky {
		position: static !important;
	}
}
</style>
